import { useState } from 'react';
import { cx } from '@ui/utils';
import { IconMaximize } from '@ui/icons';
import DiscoverTab from './DiscoverTab';
import ReorderTab from './ReorderTab';
import SnapshotTab from './SnapshotTab';

const TAB_TYPES = {
  snapshot: {
    name: 'Snapshot',
    icon: <IconMaximize size={16} className="mr-2" />,
    bgClassName: 'bg-andisor-red',
  },
  discover: {
    name: 'Discover',
    icon: null,
    bgClassName: 'bg-andisor-pink',
  },
  reorder: {
    name: 'Reorder',
    icon: null,
    bgClassName: 'bg-andisor-blue',
  },
};

type TabType = keyof typeof TAB_TYPES;

const AnalyticsBox = ({ hideBox }: { hideBox: () => void }) => {
  const [activeTab, setActiveTab] = useState<TabType>('snapshot');

  return (
    <div className="h-[601px] w-[455px] animate-slide-up-fade-long">
      <div className="flex">
        {Object.keys(TAB_TYPES).map(tabType => {
          const tabData = TAB_TYPES[tabType];
          return (
            <button
              key={tabType}
              type="button"
              className={cx([
                'flex w-[33.33%] items-center justify-center rounded-tl-xl  rounded-tr-xl py-2 font-semibold transition-colors',
                tabData.bgClassName,
              ])}
              onClick={() => {
                setActiveTab(tabType as TabType);
              }}
            >
              {tabData.icon}
              {tabData.name}
            </button>
          );
        })}
      </div>
      {activeTab === 'snapshot' && <SnapshotTab hideBox={hideBox} />}
      {activeTab === 'discover' && <DiscoverTab hideBox={hideBox} />}
      {activeTab === 'reorder' && <ReorderTab hideBox={hideBox} />}
    </div>
  );
};

export default AnalyticsBox;
