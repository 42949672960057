import { useMediaQuery } from '@mantine/hooks';
import { z } from 'zod';
import { CarouselAutoplay, Heading, Text } from '@ui/core';
import { IconCustomQuote } from '@ui/icons';
import { getHomepageCMSSchema } from '@api/buyers/cms';

const HEADING_TEXT = 'Andisor drives results that matter';

type TestimonialsSectionProps = {
  data: z.infer<typeof getHomepageCMSSchema>['customerTestimonial'] | undefined;
};

const TestimonialsSection = ({ data }: TestimonialsSectionProps) => {
  const matches = useMediaQuery('(max-width: 30em)');

  return (
    <section className="mt-16 flex justify-center bg-andisor-natural-100 pt-16 laptop:px-10 mobile:mt-10 mobile:px-5 mobile:pt-10">
      <div className="w-full max-w-8xl">
        <div className="mb-4 flex flex-col items-center">
          <Text variant="outline" className="mobile:text-xs">
            Testimonials
          </Text>
          <Heading size="h4" className="mt-5 text-center mobile:text-2xl">
            {HEADING_TEXT}
          </Heading>
        </div>
        <div className="mobile:pb-7">
          <CarouselAutoplay
            height={matches ? 200 : 300}
            withIndicators={false}
            classNames={{
              slide: 'flex items-center justify-center',
              controls:
                'top-[38%] laptop:top-[14%] mobile:bottom-[-90%] mobile:justify-center mobile:gap-6',
            }}
          >
            {data?.map((customer, idx) => (
              <div key={idx} className="flex flex-col items-center justify-center">
                <IconCustomQuote size={80} className="mb-4 text-andisor-navy" />
                <Text size="s3" className="mb-4 text-center mobile:text-xl">
                  {customer.text}
                </Text>
                <div>
                  <Text size="s1" className="mb-4 text-center font-bold mobile:text-base" span>
                    {customer.name}
                  </Text>
                  {' — '}
                  <Text size="s1" className="mb-4 text-center mobile:text-base" span>
                    {customer.profession}
                  </Text>
                </div>
              </div>
            ))}
          </CarouselAutoplay>
        </div>
      </div>
    </section>
  );
};

export default TestimonialsSection;
