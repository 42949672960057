import Link from 'next/link';
import { z } from 'zod';
import { Carousel, Text, UnstyledButton } from '@ui/core';
import { cx } from '@ui/utils';
import { getHomepageCMSSchema } from '@api/buyers/cms';
import useIsNewBalanceUser from '@hooks/useIsNewBalanceUser';
import { ROUTES } from '@constants/route';

type CategoriesProps = {
  data: z.infer<typeof getHomepageCMSSchema>['exploreCategories'];
};

const Categories = ({ data }: CategoriesProps) => {
  const { isNewBalanceUser } = useIsNewBalanceUser();
  return (
    <div className="mb-8 laptop:pl-10 mobile:pl-5">
      <Carousel
        height={180}
        slideSize={150}
        classNames={{ container: cx(isNewBalanceUser && 'justify-center') }}
      >
        {data?.map((category, idx) => (
          <UnstyledButton
            key={idx}
            component={Link}
            href={category?.path || ROUTES.COMMON.HOME}
            className="group flex w-fit flex-col items-center"
          >
            <div className="flex items-center justify-center rounded border border-solid border-andisor-gray-300 bg-andisor-gray-50 p-7 transition-all group-hover:shadow-xl">
              <div
                className="h-[70px] w-[70px] bg-contain bg-center bg-no-repeat"
                style={{
                  backgroundImage: `url(${category?.imageURL})`,
                }}
              ></div>
            </div>
            <Text className="absolute bottom-4 -ml-4 -mr-4 mt-3 inline transition-all group-hover:font-bold">
              {category?.name}
            </Text>
          </UnstyledButton>
        ))}
      </Carousel>
    </div>
  );
};

export default Categories;
