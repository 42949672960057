export const EVENTS = {
  BUYERS: {
    PRODUCT_IMPRESSION: 'product_impression_event',
    PRODUCT_CART_ADD: 'product_added_to_cart',
    CHECKOUT_CLICKED: 'checkout_clicked',
    PROCEED_TO_PAYMENT_CLICKED: 'proceed_to_payment_clicked',
  },
  SELLERS: {
    ACTIVE_BRAND_ASSOCIATION_UPDATED: 'active_brand_association_updated',
  },
};
