import Image from 'next/image';
import Link from 'next/link';
import { Text } from '@ui/core';
import { LOGO_PLACEHOLDER_URL } from '@constants';

const BrandCard = ({
  name,
  url,
  logoURL,
}: {
  name: string;
  url: string;
  logoURL: string | null;
}) => {
  return (
    <Link href={url} className="block w-fit">
      <div className="group w-[86px]">
        <div
          className="relative min-h-[90px] w-full overflow-hidden rounded"
          style={{
            boxShadow: '0 8px 16px rgba(63.75, 63.75, 63.75, 0.25)',
          }}
        >
          <Image
            src={logoURL || LOGO_PLACEHOLDER_URL}
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
            }}
            alt={name}
            width={230}
            height={320}
            className="h-[90px] w-[86px] rounded object-cover object-center"
          />
        </div>
        <Text size="c2" lineClamp={1} className="mt-4 block text-ellipsis font-bold" title={name}>
          {name}
        </Text>
      </div>
    </Link>
  );
};

export default BrandCard;
