import Image from 'next/image';
import Link from 'next/link';
import { z } from 'zod';
import { Text } from '@ui/core';
import { cx } from '@ui/utils';
import { getHomepageCMSSchema } from '@api/buyers/cms';
import { getHeroNavigationSchema } from '@api/common/browse';
import { useAuth } from '@hooks/useAuth';
import useIsCityBeachUser from '@hooks/useIsCityBeachUser';
import useIsCombatSportsUser from '@hooks/useIsCombatSportsUser';
import useIsFrontlineUser from '@hooks/useIsFrontlineUser';
import useIsKidStuffUser from '@hooks/useIsKidStuffUser';
import useIsMyerUser from '@hooks/useIsMyerUser';
import useIsNewBalanceUser from '@hooks/useIsNewBalanceUser';
import { ANDISOR_LEGAL_LINKS, ROLES } from '@constants';
import { ROUTES } from '@constants/route';
import { filteredNewBalanceNav } from '../../../utils/newBalance';

type FooterProps = {
  data: z.infer<typeof getHomepageCMSSchema>['footer'] | undefined;
  navbarData: z.infer<typeof getHeroNavigationSchema>;
};

const Footer = ({ navbarData, data }: FooterProps) => {
  const { user } = useAuth();
  const { isMyerUser } = useIsMyerUser();
  const { isFrontlineUser } = useIsFrontlineUser();
  const { isNewBalanceUser } = useIsNewBalanceUser();
  const { isCityBeachUser } = useIsCityBeachUser();
  const { isKidStuffUser } = useIsKidStuffUser();
  const { isCombatSportsUser } = useIsCombatSportsUser();

  return (
    <footer
      className={cx(
        'flex justify-center bg-andisor-navy py-10 text-white laptop:px-10 mobile:px-5',
        (isMyerUser || isFrontlineUser || isNewBalanceUser) && 'bg-black',
        isCityBeachUser && 'bg-[#0A2F87]',
        (isKidStuffUser || isCombatSportsUser) && 'border-t bg-[#fff] text-andisor-navy'
      )}
    >
      <div className="w-full max-w-8xl">
        <div className="flex w-full justify-between mobile:flex-col mobile:gap-10">
          <div>
            <Link href={ROUTES.COMMON.HOME} className="mb-8 inline-block">
              {isMyerUser ? (
                <div className="relative h-[54px] w-[130px] overflow-hidden tablet:h-[42px] tablet:w-[102px]">
                  <Image src="/assets/images/myer-logo-light.svg" alt="Andisor" fill />
                </div>
              ) : isFrontlineUser ? (
                <div className="relative h-[58px] w-[62px] overflow-hidden tablet:h-[48px] tablet:w-[50px]">
                  <Image src="/assets/images/frontline-logo.png" alt="Andisor" fill />
                </div>
              ) : isNewBalanceUser ? (
                <div className="relative h-[54px] w-[98px] overflow-hidden tablet:h-[42px] tablet:w-[102px]">
                  <Image src="/assets/images/newbalance_logo_light.png" alt="Andisor" fill />
                </div>
              ) : isCityBeachUser ? (
                <div className="relative h-[48px] w-[120px] overflow-hidden">
                  <Image src="/assets/images/citybeach_logo.png" alt="Andisor" fill />
                </div>
              ) : isKidStuffUser ? (
                <div className="relative h-[40px] w-[120px] overflow-hidden">
                  <Image src="/assets/images/kidstuff_logo.png" alt="Andisor" fill />
                </div>
              ) : isCombatSportsUser ? (
                <div className="relative h-[60px] w-[107px] overflow-hidden">
                  <Image src="/assets/images/adidas_logo.jpg" alt="Andisor" fill />
                </div>
              ) : (
                <div className="relative ml-[-10px] h-[54px] w-[130px] overflow-hidden tablet:h-[50px] tablet:w-[120px]">
                  <Image src="/assets/logo/logo_full_light.svg" alt="Andisor" fill />
                </div>
              )}
              <span className="sr-only">Home</span>
            </Link>
            <div className="flex gap-4">
              <a
                href={data?.linkedinUrl || ROUTES.COMMON.HOME}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Image
                  src="/assets/images/icons/linkedin.svg"
                  width={16}
                  height={16}
                  alt="linkedin"
                  className={cx((isKidStuffUser || isCombatSportsUser) && 'invert')}
                />
              </a>
              <a
                href={data?.facebookUrl || ROUTES.COMMON.HOME}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Image
                  src="/assets/images/icons/facebook.svg"
                  width={18}
                  height={18}
                  alt="linkedin"
                  className={cx((isKidStuffUser || isCombatSportsUser) && 'invert')}
                />
              </a>
              <a
                href={data?.instagramUrl || ROUTES.COMMON.HOME}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Image
                  src="/assets/images/icons/instagram.svg"
                  width={18}
                  height={18}
                  alt="linkedin"
                  className={cx((isKidStuffUser || isCombatSportsUser) && 'invert')}
                />
              </a>
              <a
                href={data?.twitterUrl || 'https://twitter.com/andisorinc'}
                rel="noopener noreferrer"
              >
                <Image
                  src="/assets/images/icons/twitter.svg"
                  width={18}
                  height={18}
                  alt="linkedin"
                  className={cx((isKidStuffUser || isCombatSportsUser) && 'invert')}
                />
              </a>
            </div>
          </div>
          <div className="grid grid-cols-2 mobile:grid-cols-1 mobile:gap-10">
            <div className="flex flex-col pr-24">
              <Text className="mb-6 text-andisor-pink mobile:text-sm">Shop</Text>
              {(isNewBalanceUser
                ? filteredNewBalanceNav(navbarData)
                : isKidStuffUser
                  ? navbarData.filter(dt => dt.name === 'Kids & Baby')
                  : isCityBeachUser
                    ? navbarData
                        .filter(dt => dt.name !== 'Beauty' && dt.name !== 'Home')
                        .map(dt => ({ ...dt, name: dt.name === 'Kids & Baby' ? 'Kids' : dt.name }))
                    : navbarData
              )?.map(nav => (
                <Link
                  key={nav.path}
                  className="mb-3 w-fit text-sm hover:underline"
                  href={nav.path.replace('/navigation', ROUTES.BUYERS.BROWSE)}
                >
                  {nav.name}
                </Link>
              ))}
              {isNewBalanceUser ? (
                <Link
                  className="w-fit text-sm hover:underline"
                  href={ROUTES.SELLERS.NEW_BALANCE_BRAND_PAGE}
                >
                  Store
                </Link>
              ) : (
                <Link className="w-fit text-sm hover:underline" href={ROUTES.COMMON.BRANDS}>
                  Brands
                </Link>
              )}
            </div>
            <div className="flex flex-col">
              <Text className="mb-6 text-andisor-pink mobile:text-sm">Company</Text>
              <a
                className="mb-3 w-fit text-sm hover:underline"
                href={data?.ourStoryUrl || ROUTES.COMMON.HOME}
                target="_blank"
                rel="noopener noreferrer"
              >
                Our Story
              </a>
              <a
                className="mb-3 w-fit text-sm hover:underline"
                href={data?.blogUrl || ROUTES.COMMON.HOME}
                target="_blank"
                rel="noopener noreferrer"
              >
                Blog
              </a>
              <a
                className="mb-3 w-fit text-sm hover:underline"
                href={data?.pressUrl || ROUTES.COMMON.HOME}
                target="_blank"
                rel="noopener noreferrer"
              >
                Press
              </a>
              <a
                className="mb-3 w-fit text-sm hover:underline"
                href={data?.helpCentreUrl || ROUTES.COMMON.HOME}
                target="_blank"
                rel="noopener noreferrer"
              >
                Help Centre
              </a>
              <a
                className="mb-3 w-fit text-sm hover:underline"
                href={data?.contactUsUrl || ROUTES.COMMON.HOME}
                target="_blank"
                rel="noopener noreferrer"
              >
                Contact us
              </a>
            </div>
          </div>
        </div>
        <div className="mt-14 border-t border-solid border-andisor-blue-300/10 pt-8 mobile:mt-10">
          <div className="flex w-full justify-between tablet:gap-6 mobile:flex-col mobile:gap-10">
            <div className="w-full max-w-[22rem] tablet:max-w-[18rem]">
              <Text
                size="c2"
                style={
                  isKidStuffUser || isCombatSportsUser
                    ? { color: '#1E1A37' }
                    : {
                        background: `linear-gradient(91.92deg, rgb(var(--color-red-7)) -26.26%, rgb(var(--color-pink-4)) 38.19%, rgb(var(--color-blue-6)) 91.58%)`,
                        WebkitBackgroundClip: 'text',
                        WebkitTextFillColor: 'transparent',
                      }
                }
              >
                {data?.missionText}
              </Text>
            </div>
            <div className="text-sm">
              <div className="grid grid-cols-2 mobile:grid-cols-1 mobile:gap-1">
                <div className="flex flex-col pr-24 tablet:pr-16">
                  <a
                    href={
                      user?.role === ROLES.BRAND
                        ? ANDISOR_LEGAL_LINKS.SELLER_TERMS_AND_CONDITION
                        : ANDISOR_LEGAL_LINKS.BUYER_TERMS_AND_CONDITION
                    }
                    className={cx(
                      'mb-1 text-andisor-blue-300/50 hover:underline',
                      isKidStuffUser || (isCombatSportsUser && 'text-andisor-navy')
                    )}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Terms & Conditions
                  </a>
                  <a
                    href={ANDISOR_LEGAL_LINKS.PRIVACY_POLICY}
                    className={cx(
                      'text-andisor-blue-300/50 hover:underline',
                      isKidStuffUser || (isCombatSportsUser && 'text-andisor-navy')
                    )}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Privacy Policy
                  </a>
                </div>
                <div className="flex flex-col mobile:flex-row mobile:justify-between">
                  <a
                    href={ANDISOR_LEGAL_LINKS.RETURN_AND_REFUND_POLICY}
                    className={cx(
                      'mb-1 text-andisor-blue-300/50 hover:underline',
                      isKidStuffUser || (isCombatSportsUser && 'text-andisor-navy')
                    )}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Refund Policy
                  </a>
                  <Text
                    size="c1"
                    className={cx(
                      'text-andisor-blue-300/50 mobile:mt-[-18px] mobile:max-w-[128px]',
                      isKidStuffUser || (isCombatSportsUser && 'text-andisor-navy')
                    )}
                  >
                    © 2024 Andisor™. All Rights Reserved.
                  </Text>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
