import { useMemo } from 'react';
import Link from 'next/link';
import { Carousel, Text, UnstyledButton } from '@ui/core';
import { IconCustomArrowRightMedium, IconFlame, IconTimeline, IconX } from '@ui/icons';
import useIsCityBeachUser from '@hooks/useIsCityBeachUser';
import useIsKidStuffUser from '@hooks/useIsKidStuffUser';
import useIsNewBalanceUser from '@hooks/useIsNewBalanceUser';
import { cityBeachDiscoverTabConfig } from '@data/analytics/citybeach';
import { kidstuffDiscoverTabConfig } from '@data/analytics/kidstuff';
import { NBcardConfig } from '@data/analytics/newbalance';
import { ROUTES } from '@constants/route';
import BrandCard from './BrandCard';
import Filters from './Filters';
import ProductCard from './ProductCard';

const cardConfig = {
  topWeeklyProducts: {
    headingText: 'Top Products this week',
    products: [
      {
        imageURL: `https://media.crystallize.com/andisor-demo/23/12/7/117/b543k.jpg`,
        productURL: '/product/65713fb39ff7a8dc6cc3beb8?variant=Chestnut',
        brandName: 'R M Williams',
        productName: 'Comfort Craftsman - Chestnut',
      },
      {
        imageURL: `https://media.crystallize.com/andisor-demo/23/10/29/1900/jd_636662_e.jpg`,
        productURL: '/product/653eef24035f4f0e1fb9eed1?variant=White',
        brandName: 'Tommy Hilfiger',
        productName: 'Daniel',
      },
      {
        imageURL: `https://media.crystallize.com/andisor-demo/23/10/29/1418/air-max-90-g-golf-shoe-ns3rc6.jpg`,
        productURL:
          '/product/653e372f035f4f0e1fb9ea5b?variant=Iron%20Grey/Black/Infrared%2023/White',
        brandName: 'Nike',
        productName: 'Air Max 90 G',
      },
      {
        imageURL: `https://media.crystallize.com/andisor-demo/23/10/30/1554/jd_40cma004502h_c_0001_e.jpg`,
        productURL: '/product/653fa4f4035f4f0e1fba07cc?variant=Black',
        brandName: 'Lacoste',
        productName: 'Croco Slide',
      },
    ],
  },
  topBrands: {
    headingText: 'Hot brands in your portfolio',
    brands: [
      {
        name: 'R.M. Williams',
        url: '/brands/R%20M%20WILLIAMS',
        logoURL: 'https://media.crystallize.com/andisor-staging/23/10/18/2/rm-williams-squared.png',
        categories: ['Clothing', 'Shoes'],
        discount: '50%',
        moq: '$500',
        shipsFrom: 'Australia',
        pricing: 'Premium',
        values: ['Australian', 'Social Good', 'Resposible Sourced'],
      },
      {
        name: 'Nike',
        url: '/brands/Nike',
        logoURL: 'https://media.crystallize.com/andisor-staging/23/8/3/14/46.png',
        categories: ['Clothing'],
        discount: '50%',
        moq: '$200',
        shipsFrom: 'Australia',
        pricing: 'Popular',
        values: ['Cruelty-Free', 'Responsibly Sourced'],
      },
      {
        name: 'Lacoste',
        url: '/brands/Lacoste',
        logoURL: 'https://media.crystallize.com/andisor-staging/23/8/3/3/32.png',
        categories: ['Clothing'],
        discount: '20%',
        moq: '$10',
        shipsFrom: 'Australia',
        pricing: 'Premium',
        values: ['Women-led', 'Organic', 'Vegan'],
      },
      {
        name: 'Adidas',
        url: '/brands/Adidas',
        logoURL: 'https://media.crystallize.com/andisor-staging/23/8/3/1/adi-logo-square.png',
        categories: ['Clothing', 'Shoes'],
        discount: '50%',
        moq: '$500',
        shipsFrom: 'Australia',
        pricing: 'Premium',
        values: ['Social Good', 'Resposible Sourced', 'Carbon Neutral'],
      },
    ],
  },
};

const DiscoverTab = ({ hideBox }: { hideBox: () => void }) => {
  const { isNewBalanceUser } = useIsNewBalanceUser();
  const { isCityBeachUser } = useIsCityBeachUser();
  const { isKidStuffUser } = useIsKidStuffUser();

  const discoverTabConfig = useMemo(() => {
    if (isNewBalanceUser) {
      return NBcardConfig;
    }
    if (isCityBeachUser) {
      return cityBeachDiscoverTabConfig;
    }
    if (isKidStuffUser) {
      return kidstuffDiscoverTabConfig;
    }
    return cardConfig;
  }, [isNewBalanceUser, isCityBeachUser, isKidStuffUser]);

  return (
    <div>
      <div className="bg-andisor-pink p-6 pb-8 pt-4">
        <div className="mb-6 flex justify-between gap-2">
          <Filters bgClassName="bg-andisor-pink" />
          <UnstyledButton className="ml-auto" onClick={() => hideBox()}>
            <IconX stroke={1.5} size={20} />
          </UnstyledButton>
        </div>
        <div>
          <Text className="flex items-center">
            <IconTimeline className="mr-2" size={18} />
            {discoverTabConfig.topWeeklyProducts.headingText}
          </Text>
          <>
            <Carousel height={150} slideSize={105} className="mt-5" withControls={false}>
              {discoverTabConfig.topWeeklyProducts.products.map((product, idx) => (
                <ProductCard
                  key={idx}
                  name={product.productName}
                  imageURL={product.imageURL}
                  url={product.productURL}
                  brandName={product.brandName}
                />
              ))}
            </Carousel>
          </>
        </div>
      </div>
      <div className="bg-andisor-gray p-6">
        <div>
          <Text className="flex items-center">
            <IconFlame className="mr-2" size={18} />
            {isNewBalanceUser
              ? NBcardConfig.newProducts.headingText
              : cardConfig.topBrands.headingText}
          </Text>
          {isNewBalanceUser ? (
            <>
              <Carousel height={150} slideSize={105} className="mt-5" withControls={false}>
                {NBcardConfig.newProducts.products.map((product, idx) => (
                  <ProductCard
                    key={idx}
                    name={product.productName}
                    imageURL={product.imageURL}
                    url={product.productURL}
                    brandName={product.brandName}
                  />
                ))}
              </Carousel>
            </>
          ) : (
            <>
              <Carousel height={150} slideSize={105} className="mt-5" withControls={false}>
                {discoverTabConfig.topBrands.brands.map((brand, idx) => (
                  <BrandCard key={idx} name={brand.name} logoURL={brand.logoURL} url={brand.url} />
                ))}
              </Carousel>
            </>
          )}
        </div>
        <UnstyledButton
          component={Link}
          href={ROUTES.BUYERS.ANALYTICS}
          className="mt-1 flex justify-end border-b border-solid border-andisor-navy bg-andisor-gray pb-2"
        >
          <IconCustomArrowRightMedium className="transition-transform hover:translate-x-1" />
        </UnstyledButton>
      </div>
    </div>
  );
};

export default DiscoverTab;
