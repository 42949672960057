import Image from 'next/image';
import { HorizontalTicker } from 'react-infinite-ticker';
import { z } from 'zod';
import { Heading, Text } from '@ui/core';
import { getHomepageCMSSchema } from '@api/buyers/cms';

const HEADING_TEXT = 'Supported and Trusted by';

type BrandsShowcaseProps = {
  brandsShowcase: z.infer<typeof getHomepageCMSSchema>['brandsShowcase'] | undefined;
};

const PartnershipsSection = ({ brandsShowcase }: BrandsShowcaseProps) => {
  const renderLogos = () => {
    return brandsShowcase?.map((brand, idx) => (
      <div
        className="relative mx-6 flex h-[100px] w-[140px] items-center overflow-hidden"
        key={idx}
      >
        <Image
          src={brand.imageUrl}
          alt="foo"
          width={140}
          height={100}
          className="object-cover object-center"
        />
      </div>
    ));
  };

  return (
    <section className="flex justify-center pt-16 laptop:px-10 mobile:px-5 mobile:pt-10">
      <div className="w-full max-w-8xl">
        <div className="mb-10 flex flex-col items-center">
          <Text variant="outline" className="mobile:text-xs">
            Partnerships
          </Text>
          <Heading size="h4" className="mt-5 mobile:text-2xl">
            {HEADING_TEXT}
          </Heading>
        </div>
        <div>
          <HorizontalTicker duration={25000}>{renderLogos()}</HorizontalTicker>
        </div>
      </div>
    </section>
  );
};

export default PartnershipsSection;
