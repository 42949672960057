import { useCallback, useState } from 'react';

interface useModalProps {
  openOnMount?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  initialData?: any;
  onOpen?: () => void; // callback fn called when open fired
  onClose?: () => void; // callback fn called when close fired
}

type useModalInitialStateType = {
  isOpen: boolean | string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data?: any;
};

type OpenPayload = {
  type?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data?: any;
};

export const useOpen = (props = {} as useModalProps) => {
  const { openOnMount, initialData, onOpen, onClose } = props;

  const [openState, setOpenState] = useState<useModalInitialStateType>({
    isOpen: openOnMount ?? false,
    data: initialData,
  });

  const open = useCallback(
    ({ type, data }: OpenPayload = {}) => {
      setOpenState(prevOpenState => {
        if (!prevOpenState.isOpen) {
          onOpen?.();
          return { isOpen: type ?? true, data };
        }
        return prevOpenState;
      });
    },
    [onOpen]
  );

  const close = useCallback(() => {
    setOpenState(prevOpenState => {
      if (prevOpenState.isOpen) {
        onClose?.();
        return { ...prevOpenState, isOpen: false };
      }
      return prevOpenState;
    });
  }, [onClose]);

  const toggle = useCallback(
    ({ type, data }: OpenPayload = {}) => {
      openState.isOpen ? close() : open({ type, data });
    },
    [open, close, openState.isOpen]
  );

  const setOpen = useCallback(
    (bool: boolean, { type, data }: OpenPayload = {}) => {
      bool ? open({ type, data }) : close();
    },
    [open, close]
  );

  return {
    isOpen: openState.isOpen,
    data: openState.data,
    open,
    close,
    toggle,
    setOpen,
  };
};
