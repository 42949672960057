import { useMemo } from 'react';
import Link from 'next/link';
import { Carousel, Text, UnstyledButton } from '@ui/core';
import { IconCustomArrowRightMedium, IconRuler2, IconX } from '@ui/icons';
import useIsCityBeachUser from '@hooks/useIsCityBeachUser';
import useIsKidStuffUser from '@hooks/useIsKidStuffUser';
import useIsNewBalanceUser from '@hooks/useIsNewBalanceUser';
import { cityBeachReorderTabConfig } from '@data/analytics/citybeach';
import { kidstuffReorderTabConfig } from '@data/analytics/kidstuff';
import { NBReorderingCardConfig } from '@data/analytics/newbalance';
import { ROUTES } from '@constants/route';
import Filters from './Filters';
import ProductCard from './ProductCard';

const cardConfig = {
  topOutOfCoreSizeProducts: {
    headingText: 'Out-of-Core-Sizes Products',
    products: [
      {
        imageURL: `https://media.crystallize.com/andisor-demo/23/10/29/1544/jordan-adg-4-golf-shoes-053rhm.jpg`,
        productURL:
          '/product/653e3863035f4f0e1fb9ea72?variant=Phantom/White/Light%20Bone/Barely%20Volt',
        brandName: 'Nike',
        productName: 'Jordan ADG 4 - White',
      },
      {
        imageURL: `https://media.crystallize.com/andisor-demo/23/10/30/1250/jd_641572_e.jpg`,
        productURL: '/product/653f9d1ba1a21a49c2f4fcb9?variant=White',
        brandName: 'Lacoste',
        productName: 'Powercourt 121',
      },
      {
        imageURL: `https://media.crystallize.com/andisor-demo/23/12/7/231/b543y.jpg`,
        productURL: '/product/657140dcf3da6ee2d925f1a0?variant=Chocolate',
        brandName: 'R M Williams',
        productName: 'Dynamic Flex Craftsman - Chocolate',
      },
      {
        imageURL: `https://media.crystallize.com/andisor-demo/23/10/29/1900/jd_fw0fw05905ybr_c_0010_d.jpg`,
        productURL: '/product/653eeb201b5028e484db9838?variant=White',
        brandName: 'Tommy Hilfiger',
        productName: 'Katerina',
      },
    ],
  },
  topReorderProducts: {
    headingText: 'Reorder Recommendations',
    products: [
      {
        imageURL: `https://media.crystallize.com/andisor-demo/23/10/29/1418/air-max-90-g-golf-shoe-ns3rc6.jpg`,
        productURL:
          '/product/653e372f035f4f0e1fb9ea5b?variant=Iron%20Grey/Black/Infrared%2023/White',
        brandName: 'Nike',
        productName: 'Air Max 90 G',
      },
      {
        imageURL: `https://media.crystallize.com/andisor-demo/23/12/7/117/b543k.jpg`,
        productURL: '/product/65713fb39ff7a8dc6cc3beb8?variant=Chestnut',
        brandName: 'R M Williams',
        productName: 'Comfort Craftsman - Chestnut',
      },
      {
        imageURL: `https://media.crystallize.com/andisor-demo/23/12/7/231/b543y.jpg`,
        productURL: '/product/657140dcf3da6ee2d925f1a0?variant=Chocolate',
        brandName: 'R M Williams',
        productName: 'Dynamic Flex Craftsman - Chocolate',
      },
      {
        imageURL: `https://media.crystallize.com/andisor-demo/23/10/29/1342/air-jordan-12-low-golf-shoes-bnwnnt.jpg`,
        productURL:
          '/product/653e3665a1a21a49c2f4df4f?variant=White/White/Metallic%20Silver/Varsity%20Red',
        brandName: 'Nike',
        productName: 'Air Jordan 12 Low',
      },
    ],
  },
};

const ReorderTab = ({ hideBox }: { hideBox: () => void }) => {
  const { isNewBalanceUser } = useIsNewBalanceUser();
  const { isCityBeachUser } = useIsCityBeachUser();
  const { isKidStuffUser } = useIsKidStuffUser();

  const reorderTabConfig = useMemo(() => {
    if (isNewBalanceUser) {
      return NBReorderingCardConfig;
    }
    if (isCityBeachUser) {
      return cityBeachReorderTabConfig;
    }
    if (isKidStuffUser) {
      return kidstuffReorderTabConfig;
    }
    return cardConfig;
  }, [isNewBalanceUser, isCityBeachUser, isKidStuffUser]);

  return (
    <div>
      <div className="bg-andisor-blue p-6 pb-8 pt-4">
        <div className="mb-6 flex justify-between gap-2">
          <Filters bgClassName="bg-andisor-blue text-white" />
          <UnstyledButton className="ml-auto" onClick={() => hideBox()}>
            <IconX stroke={1.5} size={20} className="text-white" />
          </UnstyledButton>
        </div>
        <div>
          <Text className="flex items-center text-white">
            ✨ &nbsp;
            {reorderTabConfig.topReorderProducts.headingText}
          </Text>
          <Carousel height={150} slideSize={105} className="mt-5" withControls={false}>
            {reorderTabConfig.topReorderProducts.products.map((product, idx) => (
              <ProductCard
                key={idx}
                name={product.productName}
                imageURL={product.imageURL}
                url={product.productURL}
                brandName={product.brandName}
                className="text-white"
              />
            ))}
          </Carousel>
        </div>
      </div>
      <div className="bg-andisor-gray p-6">
        <div>
          <Text className="flex items-center">
            <IconRuler2 className="mr-2" size={18} />

            {reorderTabConfig.topOutOfCoreSizeProducts.headingText}
          </Text>
          <Carousel height={150} slideSize={105} className="mt-5" withControls={false}>
            {reorderTabConfig.topOutOfCoreSizeProducts.products.map((product, idx) => (
              <ProductCard
                key={idx}
                name={product.productName}
                imageURL={product.imageURL}
                url={product.productURL}
                brandName={product.brandName}
              />
            ))}
          </Carousel>
        </div>
        <UnstyledButton
          component={Link}
          href={ROUTES.BUYERS.ANALYTICS}
          className="mt-1 flex justify-end border-b border-solid border-andisor-navy bg-andisor-gray pb-2"
        >
          <IconCustomArrowRightMedium className="transition-transform hover:translate-x-1" />
        </UnstyledButton>
      </div>
    </div>
  );
};

export default ReorderTab;
