import Link from 'next/link';
import { modals } from '@mantine/modals';
import { Button, Heading, Text } from '@ui/core';
import { IconBolt } from '@ui/icons';
import OrderFormPopupItem from '@components/AddToOrderForm/OrderFormPopupItem';
import QuickBuyModal from '@components/buyers/products/QuickBuyModal/QuickBuyModal';
import Fab from '@components/Fab/Fab';
import { useAuth } from '@hooks/useAuth';
import { ROLES } from '@constants';
import { ROUTES } from '@constants/route';
import { TabDetail } from './RecommendationsSection';

type GalleryViewProps = {
  data: TabDetail['galleryView'];
  activeTab: string;
};

const GalleryView = ({ data, activeTab }: GalleryViewProps) => {
  const { isAuthenticated, user } = useAuth();

  const isRetailer = user?.role === ROLES.RETAILER;

  return (
    <div className="relative h-full w-full">
      {data && (
        <>
          <div
            className="h-full max-w-[584px] rounded-[4px] bg-andisor-gray bg-cover bg-no-repeat tablet:h-[678px] tablet:max-w-[534px] mobile:h-[341px] mobile:max-w-[100%] mobile:rounded-b-none"
            style={{
              backgroundImage: `url(${data.banner})`,
              backgroundPosition: 'center top',
            }}
          ></div>
          <div
            className="absolute right-0 top-[16%] flex w-[480px] animate-slide-down-fade-long flex-col justify-between rounded-[4px] bg-andisor-navy/95 px-7 py-8 text-white mobile:static mobile:w-full mobile:animate-none mobile:rounded-t-none mobile:py-5"
            key={activeTab + `${data.id}`}
          >
            {activeTab === 'products' && isAuthenticated && isRetailer && (
              <div className="absolute right-0 top-12">
                <Fab
                  actions={[
                    ...(!data?.indentOnly
                      ? [
                          {
                            label: 'Quick Buy',
                            icon: <IconBolt />,
                            onClick: () => {
                              modals.open({
                                title: 'Quick Buy',
                                size: 'auto',
                                children: (
                                  <QuickBuyModal
                                    closeModal={() => modals.closeAll()}
                                    productId={`${data.id}`}
                                  />
                                ),
                              });
                            },
                            className: 'bg-andisor-blue',
                          },
                        ]
                      : []),
                    {
                      customComponent: props => (
                        <OrderFormPopupItem
                          productId={`${data.id}`}
                          variantId={data.variantId}
                          isParentOpen={props.fabOpen}
                          {...props}
                        />
                      ),
                    },
                  ]}
                />
              </div>
            )}
            <div className="mb-8 flex items-center gap-3 mobile:mb-6">
              <div
                className="h-[80px] w-[80px] rounded-full bg-white bg-cover bg-no-repeat mobile:h-[56px] mobile:w-[56px]"
                style={{
                  backgroundImage: `url(${data.logo})`,
                  backgroundPosition: 'center top',
                }}
              ></div>
              {data.title && (
                <Text size="s2" className="font-bold mobile:text-lg">
                  {data.title}
                </Text>
              )}
            </div>
            <Heading size="h3" className="mb-4 line-clamp-6 mobile:mb-3 mobile:text-2xl">
              {data.subTitle}
            </Heading>
            {data.description && (
              <Text size="p1" className="mb-10 mobile:text-sm" lineClamp={data.id ? 3 : 6}>
                {data.description}
              </Text>
            )}
            <Button
              component={Link}
              href={data.exploreLink || ROUTES.COMMON.HOME}
              color="pink"
              leftArrow
            >
              Explore
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

export default GalleryView;
