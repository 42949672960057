import { z } from 'zod';
import { Heading } from '@ui/core';
import { getHomepageCMSSchema } from '@api/buyers/cms';
import useIsNewBalanceUser from '@hooks/useIsNewBalanceUser';
import Categories from './Categories';
import Curations from './Curations';
import TrendingTags from './TrendingTags';

type ExploreSectionProps = {
  categories: z.infer<typeof getHomepageCMSSchema>['exploreCategories'] | undefined;
  curations: z.infer<typeof getHomepageCMSSchema>['exploreCurations'] | undefined;
  tags: z.infer<typeof getHomepageCMSSchema>['trendingTags'] | undefined;
};

const HEADING_TEXT = 'Explore';

const ExploreSection = ({ categories, curations, tags }: ExploreSectionProps) => {
  const { isNewBalanceUser } = useIsNewBalanceUser();

  return (
    <section className="flex justify-center pt-16 mobile:pt-10">
      <div className="w-full max-w-8xl">
        <Heading size="h4" className="mb-10 text-center mobile:text-2xl">
          {HEADING_TEXT}
        </Heading>
        <Categories data={categories} />
        <Curations data={curations} />
        {!isNewBalanceUser && <TrendingTags data={tags} />}
      </div>
    </section>
  );
};

export default ExploreSection;
