import { modals } from '@mantine/modals';
import { z } from 'zod';
import { Button, Heading, Text } from '@ui/core';
import HelpRequestModal from '@components/HelpRequestModal/HelpRequestModal';
import { getHomepageCMSSchema } from '@api/buyers/cms';

type CallbackSectionProps = {
  data: z.infer<typeof getHomepageCMSSchema>['requestCallback'] | undefined;
};

const CallbackSection = ({ data }: CallbackSectionProps) => {
  return (
    <section className="flex justify-center bg-andisor-natural-100 pb-16 pt-10 laptop:px-10 mobile:px-5 mobile:pb-10">
      <div className="w-full max-w-8xl">
        <div className="relative flex w-full flex-col items-center justify-center rounded-md bg-andisor-navy px-4 py-[7.5rem] tablet:py-20 mobile:py-10">
          <Heading size="h3" className="z-10 mb-4 max-w-5xl text-center text-white mobile:text-2xl">
            {data?.heading}
          </Heading>
          <Text className="z-10 mb-10 text-center text-white mobile:text-sm ">
            {data?.subHeading}
          </Text>
          <Button
            color="red"
            variant="outline-text-light"
            className="z-10"
            onClick={() => {
              modals.open({
                title: data?.primaryBtnText,
                children: <HelpRequestModal closeModal={() => modals.closeAll()} />,
              });
            }}
            leftArrow
          >
            {data?.primaryBtnText}
          </Button>
          <div
            className="absolute left-0 top-0 h-[300px] w-[380px] bg-contain bg-no-repeat mobile:h-[140px] mobile:w-[140px]"
            style={{
              backgroundImage: 'url(/assets/images/red_ellipse.svg)',
            }}
          ></div>
          <div
            className="absolute bottom-0 left-0 h-[150px] w-[380px] bg-contain bg-no-repeat tablet:h-[120px] mobile:h-[60px] mobile:w-[180px]"
            style={{
              backgroundImage: 'url(/assets/images/pink_ellipse.svg)',
            }}
          ></div>
          <div
            className="absolute bottom-4 right-16 h-[380px] w-[380px] bg-contain bg-no-repeat tablet:hidden mobile:right-0 mobile:top-10 mobile:block mobile:h-[150px] mobile:w-[150px]"
            style={{
              backgroundImage: 'url(/assets/images/blue_ellipse.svg)',
            }}
          ></div>
        </div>
      </div>
    </section>
  );
};

export default CallbackSection;
