import { forwardRef, useImperativeHandle, useState } from 'react';
import { ActionIcon, TooltipProps, UnstyledButton } from '@ui/core';
import { useOutsideClick } from '@ui/hooks';
import { cx } from '@ui/utils';
import { IconDots, IconX } from '@ui/icons';
import styles from './Fab.module.css';

type FabProps = {
  containerClassName?: string;
  triggerClassName?: string;
  actions: {
    label?: string;
    icon?: React.ReactNode;
    onClick?: () => void;
    className?: string;
    customComponent?: React.FunctionComponent<{
      index: number;
      className: string;
      fabOpen: boolean;
    }>;
  }[];
};

const tooltipProps: Partial<TooltipProps> = {
  position: 'left',
  withinPortal: true,
};

const Fab = forwardRef(({ containerClassName, triggerClassName, actions }: FabProps, ref) => {
  const [open, setOpen] = useState<boolean>(false);
  const containerRef = useOutsideClick(() => setOpen(false));

  useImperativeHandle(
    ref,
    () => {
      return {
        open: () => setOpen(true),
        close: () => setOpen(false),
      };
    },
    []
  );

  return (
    <div
      ref={containerRef}
      className={cx(
        'absolute bottom-0 right-0 z-20 flex items-center p-2',
        styles.fabContainer,
        containerClassName,
        { open }
      )}
      onClick={evt => {
        evt.stopPropagation();
      }}
    >
      <UnstyledButton
        className={cx(
          'relative flex h-8 w-8 items-center justify-center rounded-full bg-andisor-natural p-2 shadow-lg transition-colors duration-300',
          open && 'bg-andisor-red',
          triggerClassName
        )}
        onClick={evt => {
          evt.stopPropagation();
          setOpen(prev => !prev);
        }}
      >
        {open ? (
          <IconX size={18} className="mb-0.5 text-white" />
        ) : (
          <IconDots size={18} className="mb-0.5 text-andisor-navy" />
        )}
      </UnstyledButton>
      {actions.map((action, index) => {
        const { customComponent: CustomComponent } = action;

        const className = cx(
          'relative my-3 flex h-8 w-8 items-center justify-center rounded-full bg-andisor-blue p-2 text-white shadow-lg hover:text-white active:text-white',
          actions.length > 1 && index === actions.length - 1 && 'mb-0',
          { open },
          styles.fabAction
        );

        if (CustomComponent) {
          return <CustomComponent key={index} index={index} className={className} fabOpen={open} />;
        }

        return (
          <ActionIcon
            variant="plain"
            key={index}
            label={action.label ?? ''}
            style={{ transitionDelay: `${index * 25}ms` }}
            className={cx(className, action.className)}
            tooltipProps={tooltipProps}
            onClick={evt => {
              evt.stopPropagation();
              action.onClick?.();
            }}
          >
            {action.icon}
          </ActionIcon>
        );
      })}
    </div>
  );
});

export default Fab;
