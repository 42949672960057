import { useEffect, useRef } from 'react';

const DEFAULT_EVENTS = ['mousedown', 'touchstart'];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useOutsideClick = <T extends HTMLElement = any>(handler: () => void) => {
  const ref = useRef<T>();

  useEffect(() => {
    const listener = (event: Event) => {
      const target = event.target as HTMLElement;
      // don't close if clicks within the container or within a portal
      const hasClosestPortal = target.closest('[data-portal="true"]');
      const shouldIgnore =
        hasClosestPortal ||
        target?.hasAttribute('data-ignore-outside-clicks') ||
        (!document.body.contains(target) && target.tagName !== 'HTML');

      if (ref.current && !shouldIgnore && !ref.current.contains(target)) {
        handler();
      }
    };
    DEFAULT_EVENTS.forEach(fn => document.addEventListener(fn, listener));
    return () => {
      DEFAULT_EVENTS.forEach(fn => document.removeEventListener(fn, listener));
    };
  }, [ref, handler]);

  return ref;
};
