import Link from 'next/link';
import { z } from 'zod';
import { Button, ButtonStylesType, Heading, Text } from '@ui/core';
import { getHomepageCMSSchema } from '@api/buyers/cms';
import { ROUTES } from '@constants/route';

interface InfoCardProps {
  item: z.infer<typeof getHomepageCMSSchema>['landingPageTop']['carousels'][number];
}

const InfoCard = ({ item }: InfoCardProps) => {
  return (
    <div
      className="w-full max-w-[680px] animate-slide-down-fade-long rounded bg-white p-7 mobile:max-w-full mobile:rounded-none"
      style={{
        backgroundColor: `${item.cardBgColor}f1`, // `f1` is a transparency value in hex notation
        color: `${item.cardTextColor}`,
      }}
    >
      <Heading size="h2" className="mobile:text-3xl">
        {item.heading}
      </Heading>
      <div
        className="mt-2 h-[0.5px] w-14"
        style={{ backgroundColor: `${item.cardBorderColor}` }}
      ></div>
      <Text size="p2" className="mt-7 mobile:text-base">
        {item.subHeading}
      </Text>
      <Button
        href={item?.primaryBtnAction || ROUTES.COMMON.HOME}
        component={Link}
        className="mt-14 block w-fit mobile:mt-10"
        color={item.primaryBtnBgColor as ButtonStylesType['color']}
        leftArrow
      >
        {item?.primaryBtnText}
      </Button>
    </div>
  );
};

export default InfoCard;
