import { useEffect, useState } from 'react';
import Image from 'next/image';
import { z } from 'zod';
import { CarouselAutoplay, type Embla } from '@ui/core';
import { cx } from '@ui/utils';
import { IconMaximize } from '@ui/icons';
import { getHomepageCMSSchema } from '@api/buyers/cms';
import { useAuth } from '@hooks/useAuth';
import AnalyticsBox from '../AnalyticsBox/AnalyticsBox';
import InfoCard from './InfoCard';
import styles from './HeroSection.module.css';

type HeroSectionProps = {
  data: z.infer<typeof getHomepageCMSSchema>['landingPageTop'] | undefined;
};

const HeroSection = ({ data }: HeroSectionProps) => {
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);
  const [emblaApi, setEmblaApi] = useState<Embla | null>(null);
  const [showAnalyticsBox, setShowAnalyticsBox] = useState<boolean>(true);
  const { isAuthenticated } = useAuth();

  useEffect(() => {
    if (emblaApi) {
      const onSelect = () => {
        setActiveSlideIndex(emblaApi.selectedScrollSnap());
      };
      onSelect();
      emblaApi.on('select', onSelect);
      return () => {
        emblaApi.off('select', onSelect);
      };
    }
    return undefined;
  }, [emblaApi]);

  /*
    At mobile demension we switch to a fixed height (rem based) for the carousel, since we need to maintain the aspect ratio of the image. The height 38rem is a combination of the height of the banner image (17rem) and the assumed height of the info card ~18rem. The height of the info card is not fixed, since it depends on the length of the text.
  */
  return (
    <section className="relative flex h-[80vh] mobile:h-[38rem]">
      <CarouselAutoplay
        ref={setEmblaApi}
        classNames={{
          root: 'flex-1',
          indicators: 'mb-7 tablet:mb-5 mobile:top-[15.3rem]',
        }}
        height="100%"
        withIndicators
        withControls={false}
      >
        {data?.carousels?.map((item, idx) => (
          <div
            key={idx}
            className={cx('relative h-full w-full bg-cover bg-no-repeat', styles.bgImage)}
            style={{
              backgroundImage: `linear-gradient(180deg, rgb(var(--color-midnight-6) / 0) 0%, rgb(var(--color-midnight-6) / 0.8) 100%), url(${item.image})`,
            }}
          >
            <Image
              src={item.image}
              width={380}
              height={256}
              alt="banner image"
              className="hidden h-[17rem] w-full object-cover object-center mobile:block"
            />
            <div
              className={cx(
                'absolute inset-x-0 bottom-0 mb-26 flex h-auto w-full tablet:mb-18 mobile:static mobile:mb-0',
                styles.fluidPadding
              )}
            >
              <InfoCard item={item} key={activeSlideIndex} />
            </div>
          </div>
        ))}
      </CarouselAutoplay>
      {isAuthenticated && process.env.NEXT_PUBLIC_ENV === 'demo' && (
        <>
          {!showAnalyticsBox && (
            <div className={cx('absolute bottom-0 right-0 z-10', styles.fluidPadding)}>
              <button
                type="button"
                className="rounded-tl-lg rounded-tr-lg bg-andisor-red p-2 px-3 pt-2.5 text-sm"
                onClick={() => setShowAnalyticsBox(true)}
              >
                <IconMaximize size={18} className="mr-2" />
                Snapshot
              </button>
            </div>
          )}
          {showAnalyticsBox && (
            <div className="absolute bottom-0 right-0 z-myer-analytics-box">
              <AnalyticsBox hideBox={() => setShowAnalyticsBox(false)} />
            </div>
          )}
        </>
      )}
    </section>
  );
};

export default HeroSection;
