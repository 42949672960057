import { useCallback } from 'react';
import { usePostHog } from 'posthog-js/react';

const useAppAnalytics = () => {
  const posthog = usePostHog();

  const trackEvent = useCallback(
    (eventName: string, payload?: Record<string, any>) => {
      posthog?.capture(eventName, payload);
    },
    [posthog]
  );

  return {
    trackEvent,
  };
};

export default useAppAnalytics;
