import { useQuery } from '@tanstack/react-query';
import _ from 'lodash';
import { z } from 'zod';
import { api } from '@api';
import { getHomepageCMSSchema } from '@api/buyers/cms';
import {
  recommendedBrandsSchema,
  recommendedCollectionsSchema,
  recommendedProductsSchema,
} from '@api/buyers/recommendations';
import { ROUTES } from '@constants/route';
import { useAuth } from './useAuth';

export type DynamicBrand = z.infer<typeof recommendedBrandsSchema>;
export type DynamicProduct = z.infer<typeof recommendedProductsSchema>;
export type DynamicCollection = z.infer<typeof recommendedCollectionsSchema>;

type Brand = z.infer<typeof getHomepageCMSSchema>['recommendedBrands'][0];
type Product = z.infer<typeof getHomepageCMSSchema>['recommendedProducts'][0];
type Collection = z.infer<typeof getHomepageCMSSchema>['recommendedCollections'][0];

function mapBrand(brand: Brand): DynamicBrand {
  return {
    id: brand.id,
    name: brand.brandName,
    logoURL: brand.brandLogoURL,
    spotlightImageURL: brand.brandImageURL,
    campaignText: brand.brandSlogan,
    slogan: brand.description,
    url: brand.url,
    slug: brand.slug ?? '',
  };
}

function mapProduct(product: Product): DynamicProduct {
  return {
    id: product.id,
    variantId: product.variantId,
    indentOnly: product.indentOnly,
    attribute: product.attribute,
    name: product.name,
    description: product.description,
    imageURL: product.imageURL,
    brandId: product.brandId,
    brandName: product.brandName,
    brandLogoURL: product.brandLogoURL,
  };
}

function mapCollection(collection: Collection): DynamicCollection {
  return {
    id: collection.id,
    name: collection.name,
    description: collection.description,
    imageURL: collection.imageURL,
    brandId: collection.brandId,
    brandName: collection.brandName,
    brandLogoURL: collection.brandLogoURL,
  };
}

const MAX_GRID_ITEMS = 15;

/**
 * This function processes the recommendations by supplementing dynamic entities with static entities.
 * It also removes duplicates based on the provided keys.
 *
 * @param staticEntities - The static entities to supplement the dynamic entities.
 * @param dynamicEntities - The dynamic entities to be supplemented.
 * @param mapEntity - The function to map static entities to the same type as the dynamic entities.
 * @param keys - The keys to check for duplicates.
 * @returns A new array of processed entities.
 */
function processRecommendations<T, U>(
  staticEntities: T[],
  dynamicEntities: U[],
  mapEntity: (entity: T) => U,
  keys: (keyof U)[] = []
): U[] {
  // If there are enough dynamic entities, return them
  if (dynamicEntities.length >= MAX_GRID_ITEMS) {
    return dynamicEntities;
  }

  // Map the static entities to the same type as the dynamic entities
  const mappedStaticEntities = staticEntities.map(element => mapEntity(element));

  // Combine dynamic and static entities
  const combinedEntities = [...dynamicEntities, ...mappedStaticEntities];

  let processedEntities = combinedEntities;

  // Remove duplicates based on the provided keys, if any
  if (keys.length > 0) {
    processedEntities = _.uniqBy(combinedEntities, entity => {
      // Create a unique identifier by concatenating the values of all keys
      return keys.map(key => entity[key]).join('|');
    });
  }

  // Take enough entities to fill up to the maximum
  processedEntities = processedEntities.slice(0, MAX_GRID_ITEMS);

  return processedEntities;
}

const useRecommendations = (cmsData: z.infer<typeof getHomepageCMSSchema> | null) => {
  const { user } = useAuth();
  const retailerId: number = user?.activeRetailer?.id || 0;

  const hasEnabledMarketplace = user?.parentRetailer?.hasEnabledMarketplace;

  const isDemoEnv = process.env.NEXT_PUBLIC_ENV === 'demo';

  const fetchRecommendedBrands = useQuery({
    queryKey: [api.buyers.recommendations.getRecommendedBrands.queryKey, retailerId],
    queryFn: () => api.buyers.recommendations.getRecommendedBrands.query(retailerId),
    enabled: !isDemoEnv && !!retailerId,
  });

  const fetchRecommendedCollections = useQuery({
    queryKey: [api.buyers.recommendations.getRecommendedCollections.queryKey, retailerId],
    queryFn: () => api.buyers.recommendations.getRecommendedCollections.query(retailerId),
    enabled: !isDemoEnv && !!retailerId,
  });

  const fetchRecommendedProducts = useQuery({
    queryKey: [api.buyers.recommendations.getRecommendedProducts.queryKey, retailerId],
    queryFn: () => api.buyers.recommendations.getRecommendedProducts.query(retailerId),
    enabled: !isDemoEnv && !!retailerId,
  });

  const { brands: dynamicBrands = [] } = fetchRecommendedBrands.data?.result || {};
  const { collections: dynamicCollections = [] } = fetchRecommendedCollections.data?.result || {};
  const { products: dynamicProducts = [] } = fetchRecommendedProducts.data?.result || {};

  const brands = processRecommendations(
    hasEnabledMarketplace === false ? [] : cmsData?.recommendedBrands ?? [],
    dynamicBrands,
    mapBrand,
    ['name']
  );

  const collections = processRecommendations(
    hasEnabledMarketplace === false ? [] : cmsData?.recommendedCollections ?? [],
    dynamicCollections,
    mapCollection,
    ['id']
  );

  const products = processRecommendations(
    hasEnabledMarketplace === false ? [] : cmsData?.recommendedProducts ?? [],
    dynamicProducts,
    mapProduct,
    ['name', 'brandName']
  );

  if (products.length < MAX_GRID_ITEMS) {
    const genericEntriesToAdd =
      cmsData?.recommendedBrands.slice(0, MAX_GRID_ITEMS - products.length).map(item => ({
        id: String(item.id || ''),
        variantId: '',
        indentOnly: false,
        attribute: '',
        name: item.brandSlogan,
        brandId: item.id,
        brandName: item.brandName,
        brandLogoURL: item.brandImageURL,
        description: item.description,
        imageURL: item.brandImageURL,
        exploreLink: ROUTES.BUYERS.SUPPLIERS,
      })) || [];

    products.push(...genericEntriesToAdd);
  }

  if (collections.length < MAX_GRID_ITEMS) {
    const genericEntriesToAdd =
      cmsData?.recommendedBrands.slice(0, MAX_GRID_ITEMS - collections.length).map(item => ({
        id: item.id,
        name: item.brandSlogan,
        description: item.description,
        imageURL: item.brandImageURL,
        brandId: item.id,
        brandName: item.brandName,
        brandLogoURL: item.brandImageURL,
        exploreLink: ROUTES.BUYERS.SUPPLIERS,
      })) || [];

    collections.push(...genericEntriesToAdd);
  }

  return {
    brands,
    collections,
    products,
    loadingBrands: fetchRecommendedBrands.isLoading,
    loadingCollections: fetchRecommendedCollections.isLoading,
    loadingProducts: fetchRecommendedProducts.isLoading,
    errorBrands: fetchRecommendedBrands.isError,
    errorCollections: fetchRecommendedCollections.isError,
    errorProducts: fetchRecommendedProducts.isError,
  };
};

export default useRecommendations;
