import { useMemo, useState } from 'react';
import { domAnimation, LazyMotion, m } from 'framer-motion';
import { Combobox, Heading, Tabs, Text, UnstyledButton, useCombobox } from '@ui/core';
import { cx } from '@ui/utils';
import { IconChevronDown, IconInfoCircle } from '@ui/icons';
import { ICON_SIZE } from '@ui/constants';
import { useAuth } from '@hooks/useAuth';
import {
  type DynamicBrand,
  type DynamicCollection,
  type DynamicProduct,
} from '@hooks/useRecommendations';
import { ROUTES } from '@constants/route';
import GalleryView from './GalleryView';
import styles from './RecommendationsSection.module.css';

type RecommendationsSectionProps = {
  brands: DynamicBrand[];
  products: DynamicProduct[];
  collections: DynamicCollection[];
  loadingCollections: boolean;
  loadingBrands: boolean;
  loadingProducts: boolean;
  errorBrands: boolean;
  errorCollections: boolean;
  errorProducts: boolean;
};

const HEADING_TEXT = 'Recommendations';

const DISPLAY_LIMIT = 15;

export type TabDetail = {
  id?: number;
  logo?: string;
  title?: string;
  isEmpty?: boolean;
  galleryView?: {
    id?: number;
    brandId?: number;
    logo?: string;
    title?: string;
    subTitle?: string;
    banner?: string;
    description?: string;
    exploreLink?: string;
  };
};
type TabData = {
  brands: TabDetail[];
  products: TabDetail[];
  collections: TabDetail[];
};

const fillArray = (array, fillValue: { [key: string]: any }, length: number) => {
  return [...array, ...Array.from({ length: length - array.length }).fill(fillValue)];
};

const RecommendationsSection = ({
  brands,
  products,
  collections,
  loadingBrands,
  loadingCollections,
  loadingProducts,
  errorBrands,
  errorCollections,
  errorProducts,
}: RecommendationsSectionProps) => {
  const [activeTab, setActiveTab] = useState('brands');
  const [activeItemIdx, setActiveItemIdx] = useState(0);
  const { user } = useAuth();
  const retailerId = user?.activeRetailer?.id;

  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
  });

  const tabData: TabData = useMemo(() => {
    const data = {
      brands: [],
      products: [],
      collections: [],
    };

    if (brands) {
      const brandsList = brands.slice(0, DISPLAY_LIMIT).map(brand => {
        return {
          id: brand.id,
          logo: brand.logoURL,
          title: brand.name,
          galleryView: {
            id: brand.id,
            brandId: brand.id,
            logo: brand.logoURL,
            title: brand.name,
            subTitle: brand.campaignText,
            banner: brand.spotlightImageURL,
            description: brand.slogan,
            exploreLink: !brand.id
              ? ROUTES.BUYERS.SUPPLIERS
              : brand.url
                ? `${brand.url}`
                : `${ROUTES.COMMON.BRANDS}/${brand.slug}`,
          },
        };
      });
      data.brands =
        brandsList.length < DISPLAY_LIMIT
          ? fillArray(brandsList, { isEmpty: true }, DISPLAY_LIMIT)
          : brandsList;
    }
    if (products) {
      const productsList = products.slice(0, DISPLAY_LIMIT).map(product => {
        return {
          id: product.id,
          logo: product.imageURL,
          title: product.name,
          galleryView: {
            id: product.id,
            variantId: product.variantId,
            indentOnly: product.indentOnly,
            brandId: product.brandId,
            logo: product.brandLogoURL,
            title: product.brandName,
            subTitle: product.name,
            banner: product.imageURL,
            description: product.description,
            exploreLink: `${ROUTES.COMMON.PRODUCT}/${product.id}${
              product.attribute ? `?variant=${product.attribute}` : ''
            }`,
          },
        };
      });

      data.products =
        productsList.length < DISPLAY_LIMIT
          ? fillArray(productsList, { isEmpty: true }, DISPLAY_LIMIT)
          : productsList;
    }
    if (collections) {
      const collectionsList = collections.slice(0, DISPLAY_LIMIT).map(collection => {
        return {
          id: collection.id,
          logo: collection.imageURL,
          title: collection.name,
          galleryView: {
            id: collection.id,
            brandId: collection.brandId,
            logo: collection.brandLogoURL,
            title: collection.brandName,
            subTitle: collection.name,
            banner: collection.imageURL,
            description: collection.description,
            exploreLink: `${ROUTES.BUYERS.COLLECTIONS}/${collection.id}`,
          },
        };
      });
      data.collections =
        collectionsList.length < DISPLAY_LIMIT
          ? fillArray(collectionsList, { isEmpty: true }, DISPLAY_LIMIT)
          : collectionsList;
    }

    return data;
  }, [brands, products, collections]);

  const renderLogoGrid = (data: TabDetail[], title: string, loading: boolean, error: boolean) => {
    return (
      <LazyMotion features={domAnimation}>
        <div>
          {process.env.NEXT_PUBLIC_ENV !== 'demo' && loading && retailerId && !error && (
            <div className={cx('mb-4 flex text-center', loading && 'animate-pulse')}>
              <IconInfoCircle size={20} className="text-andisor-natural-900" />
              <Text className="ml-1 text-sm uppercase text-andisor-natural-900">
                Personalising for you...
              </Text>
            </div>
          )}
          <div className={styles.logoGrid}>
            {data?.map((item, idx) => {
              if (item.isEmpty) {
                return (
                  // render extra items to get the right hight for the gallery view
                  <div key={`${idx} ${loading}`} className="h-[114px] w-[110px] rounded-sm"></div>
                );
              }
              return (
                <m.div
                  key={`${idx} ${loading}`}
                  initial={{ opacity: 0 }}
                  transition={{ duration: 0.3, delay: idx * 0.1 }}
                  animate={{ opacity: 1 }}
                >
                  <UnstyledButton
                    title={item.title}
                    className={cx([
                      'h-[110px] w-[110px] rounded-sm border border-solid border-black/10 bg-white bg-cover bg-no-repeat',
                      activeItemIdx === idx && 'border-andisor-red shadow-xl',
                    ])}
                    style={{
                      backgroundImage: `url(${item.logo})`,
                      backgroundPosition: 'center top',
                    }}
                    onClick={() => setActiveItemIdx(idx)}
                  ></UnstyledButton>
                </m.div>
              );
            })}
          </div>
        </div>
      </LazyMotion>
    );
  };

  const renderMobileDropdown = (data: TabDetail[]) => {
    return (
      data?.length > 0 && (
        <div className="hidden mobile:flex">
          <Combobox
            onOptionSubmit={optionValue => {
              setActiveItemIdx(Number(optionValue));
              combobox.closeDropdown();
            }}
            store={combobox}
          >
            <Combobox.Target>
              <UnstyledButton
                className="flex w-full items-center rounded-md border border-solid border-andisor-red bg-white"
                onClick={() => {
                  if (combobox.dropdownOpened) {
                    combobox.closeDropdown();
                    return;
                  }
                  combobox.openDropdown();
                }}
              >
                <div className="flex-1"></div>
                <div className="flex flex-1 justify-center py-1">
                  <div
                    className="h-[64px] w-[64px] bg-white bg-cover bg-no-repeat"
                    style={{
                      backgroundImage: `url(${data?.[activeItemIdx]?.logo})`,
                      backgroundPosition: 'center top',
                    }}
                  ></div>
                </div>
                <div className="flex-1 text-right">
                  <IconChevronDown size={ICON_SIZE.sm} className="mr-3" />
                </div>
              </UnstyledButton>
            </Combobox.Target>
            <Combobox.Dropdown>
              <Combobox.Options mah={200} style={{ overflowY: 'auto' }}>
                {data?.map((item, idx) => {
                  if (item.isEmpty) return null;
                  return (
                    <Combobox.Option
                      value={`${idx}`}
                      key={idx}
                      className={cx('mx-2 flex items-center py-2 pl-3', {
                        'border-b': idx !== tabData.brands.length - 1,
                      })}
                    >
                      <div
                        className="h-[60px] w-[60px] bg-white bg-cover bg-no-repeat"
                        style={{
                          backgroundImage: `url(${item.logo})`,
                          backgroundPosition: 'center top',
                        }}
                      ></div>
                      <Text size="c2" className="ml-4" span>
                        {item.title}
                      </Text>
                    </Combobox.Option>
                  );
                })}
              </Combobox.Options>
            </Combobox.Dropdown>
          </Combobox>
        </div>
      )
    );
  };

  return (
    <section className="flex justify-center pt-16 laptop:px-10 mobile:px-5 mobile:pt-5">
      <div className="w-full max-w-8xl">
        <div className="mb-10 flex flex-col items-center">
          <Text variant="outline" className="mobile:text-xs">
            Featured
          </Text>
          <Heading size="h4" className="mt-5 mobile:text-2xl">
            {HEADING_TEXT}
          </Heading>
        </div>
        <div className={cx(styles.mainGrid)}>
          <Tabs
            value={activeTab}
            onChange={value => {
              setActiveTab(value);
              setActiveItemIdx(0);
            }}
            variant="plain"
          >
            <Tabs.List className="mb-6">
              <Tabs.Tab value="brands">Brands</Tabs.Tab>
              <Tabs.Tab value="products">Products</Tabs.Tab>
              <Tabs.Tab value="collections">Collections</Tabs.Tab>
            </Tabs.List>
            <Tabs.Panel value="brands">
              {renderLogoGrid(
                tabData.brands,
                'Your Personalised Brands',
                loadingBrands,
                errorBrands
              )}
              {renderMobileDropdown(tabData.brands)}
            </Tabs.Panel>
            <Tabs.Panel value="products">
              {renderLogoGrid(
                tabData.products,
                'Your Personalised Products',
                loadingProducts,
                errorProducts
              )}
              {renderMobileDropdown(tabData.products)}
            </Tabs.Panel>
            <Tabs.Panel value="collections">
              {renderLogoGrid(
                tabData.collections,
                'Your Personalised Collections',
                loadingCollections,
                errorCollections
              )}
              {renderMobileDropdown(tabData.collections)}
            </Tabs.Panel>
          </Tabs>
          <GalleryView
            data={tabData[activeTab]?.[activeItemIdx]?.galleryView}
            activeTab={activeTab}
          />
        </div>
      </div>
    </section>
  );
};

export default RecommendationsSection;
