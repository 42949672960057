import Link from 'next/link';
import { z } from 'zod';
import { Heading, UnstyledButton } from '@ui/core';
import { getHomepageCMSSchema } from '@api/buyers/cms';
import { ROUTES } from '@constants/route';

const HEADING_TEXT = 'Trending Tags';

type TrendingTagsProps = {
  data: z.infer<typeof getHomepageCMSSchema>['trendingTags'];
};

const TrendingTags = ({ data }: TrendingTagsProps) => {
  return (
    <div className="laptop:px-10 mobile:px-5">
      <Heading size="h6" className="mb-8 text-center">
        {HEADING_TEXT}
      </Heading>
      <div className="flex flex-wrap gap-3">
        {data && data.length > 0
          ? data?.map((tag, idx) => (
              <UnstyledButton
                key={idx}
                component={Link}
                href={tag?.path ?? ROUTES.COMMON.HOME}
                className="rounded-full border border-solid border-andisor-gray-300 bg-andisor-gray-50 px-5 py-2 text-sm transition-all hover:shadow-lg"
              >
                {tag?.name}
              </UnstyledButton>
            ))
          : null}
      </div>
    </div>
  );
};

export default TrendingTags;
