import Image from 'next/image';
import Link from 'next/link';
import { Text } from '@ui/core';
import { cx } from '@ui/utils';

type ProductProps = {
  name: string;
  imageURL: string;
  url: string;
  brandName: string;
  className?: string;
};

const ProductCard = ({ name, imageURL, url, brandName, className }: ProductProps) => {
  return (
    <Link href={url} className={cx('block w-fit', className)}>
      <div className="group w-[86px]">
        <div
          className="relative min-h-[90px] w-full overflow-hidden rounded"
          style={{
            boxShadow: '0 8px 16px rgba(63.75, 63.75, 63.75, 0.25)',
          }}
        >
          <div
            className="h-[90px] w-[86px] bg-center bg-no-repeat"
            style={{
              backgroundImage: `url(${imageURL || '/assets/images/image_unavailable.jpg'})`,
              backgroundSize: '100% 100%',
              filter: 'blur(20px)',
            }}
          ></div>
          <Image
            src={imageURL || '/assets/images/image_unavailable.jpg'}
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
            }}
            alt={name}
            width={230}
            height={320}
            className="h-[90px] w-[86px] rounded object-cover object-center"
          />
        </div>
        <Text size="c2" lineClamp={1} className="mt-4 block text-ellipsis font-bold" title={name}>
          {name}
        </Text>
        {brandName && <Text className="mt-0.5 block text-xs">{brandName}</Text>}
      </div>
    </Link>
  );
};

export default ProductCard;
