import { cx } from '@ui/utils';
import useIsCityBeachUser from '@hooks/useIsCityBeachUser';
import useIsKidStuffUser from '@hooks/useIsKidStuffUser';
import useIsNewBalanceUser from '@hooks/useIsNewBalanceUser';

const Filters = ({ bgClassName }: { bgClassName: string }) => {
  const { isNewBalanceUser } = useIsNewBalanceUser();
  const { isCityBeachUser } = useIsCityBeachUser();
  const { isKidStuffUser } = useIsKidStuffUser();

  return (
    <>
      <select
        placeholder="Category"
        className={cx('w-[58px] text-sm focus:outline-none', bgClassName)}
      >
        <option value="" selected>
          Date
        </option>
        <option value="Week 1">Week 1</option>
        <option value="Week 2">Week 2</option>
        <option value="Week 3">Week 3</option>
        <option value="Week 4">Week 4</option>
      </select>
      <select
        placeholder="Category"
        className={cx('w-[80px] text-sm focus:outline-none', bgClassName)}
      >
        <option value="" selected>
          Category
        </option>
        {isKidStuffUser ? (
          <option value="Kids">Kids</option>
        ) : (
          <>
            <option value="Women">Women</option>
            <option value="Men">Men</option>
            <option value="Beauty">Beauty</option>
            <option value="Home">Home</option>
            <option value="Kids">Kids</option>
          </>
        )}
      </select>
      <select
        placeholder="Category"
        className={cx(
          'w-[68px] text-sm focus:outline-none',
          bgClassName,
          isNewBalanceUser && 'w-[84px]'
        )}
      >
        <option value="" selected>
          {isNewBalanceUser ? 'Silhouette' : 'Brand'}
        </option>
        {isCityBeachUser ? (
          <>
            <option value="Rip Curl">Rip Curl</option>
            <option value="Nike">Nike</option>
            <option value="Adidas">Adidas</option>
            <option value="Quiksilver">Quiksilver</option>
          </>
        ) : isKidStuffUser ? (
          <>
            <option value="Jellycat">Jellycat</option>
            <option value="Bambini">Bambini</option>
            <option value="Icando Create">Icando Create</option>
            <option value="Magnetika">Magnetika</option>
          </>
        ) : (
          <>
            <option value="Nike">Nike</option>
            <option value="R M Williams">R M Williams</option>
            <option value="Tenne">Tenne</option>
            <option value="Lacoste">Lacoste</option>
          </>
        )}
      </select>
    </>
  );
};

export default Filters;
