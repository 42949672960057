import Link from 'next/link';
import { z } from 'zod';
import { Carousel, Heading, Text, UnstyledButton } from '@ui/core';
import { getHomepageCMSSchema } from '@api/buyers/cms';
import useIsNewBalanceUser from '@hooks/useIsNewBalanceUser';
import { ROUTES } from '@constants/route';

type CurationsProps = {
  data: z.infer<typeof getHomepageCMSSchema>['exploreCurations'];
};

const HEADING_TEXT = 'Curations';
const COLLECTIONS_TEXT = 'Collections';

const Curations = ({ data }: CurationsProps) => {
  const { isNewBalanceUser } = useIsNewBalanceUser();

  return (
    <div className="mb-8">
      <Heading size="h6" className="mb-8 text-center">
        {isNewBalanceUser ? COLLECTIONS_TEXT : HEADING_TEXT}
      </Heading>
      <Carousel height={462} slideSize={320} className="laptop:pl-10 mobile:pl-5">
        {data?.map((curation, idx) => (
          <UnstyledButton
            key={idx}
            component={Link}
            href={curation?.path || ROUTES.COMMON.HOME}
            className="relative"
          >
            <div
              className="h-[462px] w-[320px] rounded bg-cover bg-center bg-no-repeat"
              style={{
                backgroundImage: `linear-gradient(180deg, rgb(var(--color-midnight-6) / 0) 48%, rgb(var(--color-midnight-6) / 0.8) 100%), url(${curation?.imageURL})`,
              }}
            ></div>
            <Text className="absolute bottom-6 left-6 text-white">{curation?.name}</Text>
          </UnstyledButton>
        ))}
      </Carousel>
    </div>
  );
};

export default Curations;
