import Link from 'next/link';
import { format } from 'date-fns';
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { Text, UnstyledButton } from '@ui/core';
import { IconBox, IconCustomArrowRightMedium, IconX } from '@ui/icons';
import { useAuth } from '@hooks/useAuth';
import { ROUTES } from '@constants/route';
import Filters from './Filters';

const tabConfig = {
  salesAmount: '$505,500',
  salesAmountText: 'Sales',
  grossMargin: '72%',
  grossMarginText: 'Gross margin',
  netMargin: '49%',
  netMarginText: 'Net margin',
};

const pieChartOptions = {
  chart: {
    plotBackgroundColor: null,
    plotBorderWidth: null,
    plotShadow: false,
    type: 'pie',
    backgroundColor: null,
    width: 400,
    height: 200,
  },
  title: {
    text: null,
  },
  tooltip: {
    pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>',
  },
  accessibility: {
    point: {
      valueSuffix: '%',
    },
  },
  plotOptions: {
    pie: {
      allowPointSelect: true,
      cursor: 'pointer',
      dataLabels: {
        enabled: false,
      },
      showInLegend: true,
      borderRadius: 0,
    },
  },
  legend: {
    title: 'Aging Inventory',
    verticalAlign: 'middle',
    layout: 'vertical',
    itemStyle: {
      color: '#1E1A37',
    },
    align: 'right',
  },
  credits: {
    enabled: false,
  },
  series: [
    {
      innerSize: '50%',
      name: 'Aging Inventory',
      colorByPoint: true,
      data: [
        {
          name: '< 30 days',
          y: 30,
          color: '#357FEF',
        },
        {
          name: '31 - 90 days',
          y: 51,
          color: '#DE9CEE',
        },
        {
          name: '90+ days',
          y: 19,
          color: '#EF3346',
        },
      ],
    },
  ],
};

const SnapshotTab = ({ hideBox }: { hideBox: () => void }) => {
  const { user } = useAuth();

  return (
    <div>
      <div className="bg-andisor-red p-6 pt-4">
        <div className="flex justify-end">
          <UnstyledButton className="ml-auto" onClick={() => hideBox()}>
            <IconX className="text-white" stroke={1.5} size={20} />
          </UnstyledButton>
        </div>
        <div className="flex items-start justify-between gap-2">
          <Text size="p2" className="whitespace-nowrap text-4xl font-semibold text-white">
            Welcome <br />
            back {user?.firstName}
          </Text>
          <Text size="c2" className="mt-3 whitespace-nowrap font-bold text-white">
            {format(new Date(), 'dd MMM YYY')}
          </Text>
        </div>
        <div className="mt-1 flex justify-end gap-2">
          <Filters bgClassName="bg-andisor-red" />
        </div>
        <div className="mt-4 flex justify-between gap-4">
          <div className="flex flex-col gap-1">
            <Text className="text-4xl font-semibold">{tabConfig.salesAmount}</Text>
            <Text className="whitespace-nowrap font-semibold">{tabConfig.salesAmountText}</Text>
          </div>
          <div className="flex flex-col gap-1">
            <Text className="text-4xl font-semibold">{tabConfig.grossMargin}</Text>
            <Text className="whitespace-nowrap font-semibold">{tabConfig.grossMarginText}</Text>
          </div>
          <div className="flex flex-col gap-1">
            <Text className="text-4xl font-semibold">{tabConfig.netMargin}</Text>
            <Text className="whitespace-nowrap font-semibold">{tabConfig.netMarginText}</Text>
          </div>
        </div>
      </div>
      <div className="bg-andisor-gray p-6">
        <div className="flex items-center justify-center gap-2">
          <IconBox stroke={1.5} size={16} />
          <Text>Aging Inventory</Text>
        </div>
        <HighchartsReact highcharts={Highcharts} options={pieChartOptions} />
        <UnstyledButton
          component={Link}
          href={ROUTES.BUYERS.ANALYTICS}
          className="flex justify-end border-b border-solid border-andisor-navy bg-andisor-gray pb-2"
        >
          <IconCustomArrowRightMedium className="transition-transform hover:translate-x-1" />
        </UnstyledButton>
      </div>
    </div>
  );
};

export default SnapshotTab;
